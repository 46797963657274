// Style Scss
@import "main/typography";
@import "main/modal";
@import "main/404";
@import "main/slick";
@import "main/font";
@import "main/dark";
@import "main/style";
// react modal video
@import "node_modules/react-modal-video/scss/modal-video.scss";
